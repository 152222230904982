import { PageProps } from "gatsby"
// import { GatsbySeo } from "gatsby-plugin-next-seo"
import React from "react"

const NotFoundPage: React.FC<PageProps> = () => (
  <>
    {/* <GatsbySeo title="404: Not found" /> */}
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default NotFoundPage
